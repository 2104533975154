/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { Play } from '../../content/assets/icons/headers'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Heading1 from '../components/elements/heading1'
import BlogContainer from '../components/layout/blogContainer'
import PostsGrid from '../components/layout/postsGrid'

const PlayPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Play"
        keywords={[`kids`, `activities for children`, `activities for babies`]}
      />
      <BlogContainer>
        <div className="text-left">
          <div className="mt-24 max-h-24 md:max-h-32">
            <div className="ml-4 md:ml-0 h-24 md:h-32">
              <Play />
            </div>
          </div>
          <hr className="mt-6"></hr>
        </div>
      </BlogContainer>
      <PostsGrid posts={posts} />
    </Layout>
  )
}

export default PlayPage

export const playPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "Play" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
            featureText
            category
            tags
          }
        }
      }
    }
  }
`
